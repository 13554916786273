export default {
  data() {
    return {
      product: {
        loading: false,
        details: {},
        list: [],
        listLoadMore: [],
        meta: {},
        filters: {
          id: "",
          name: "",
          users: {
            list: [],
            selected: [],
          },
          companies: {
            list: [],
            selected: [],
          },
          companyBranches: {
            list: [],
            selected: [],
          },
          status: {
            list: [
              {
                label: "Aberto",
                value: "Aberto",
              },
              {
                label: "Ganho",
                value: "Ganho",
              },
              {
                label: "Perdido",
                value: "Perdido",
              },
            ],
            selected: [],
          },
          situation: {
            list: [
              {
                label: "Lixeira",
                value: "trash",
              },
            ],
            selected: [],
          },
          period: {
            selected: [],
          },
          price: {
            min: "",
            max: "",
          },
          order: "desc",
          orderBy: "created",
        },
        pagination: {
          page: 1,
          perPage: 20,
          total: 0,
          complete_list_total: 0,
          totalPages: 0,
        },
      },
      tempProduct: {},
      productTypes: [
        {
          label: "Cruzeiro",
          value: "cruise",
        },
        {
          label: "Hotel",
          value: "hotel",
        },
        {
          label: "Pacote",
          value: "package",
        },
        {
          label: "Resort",
          value: "resort",
        },

        {
          label: "Passagem Aérea",
          value: "flight",
        },
      ],
      productStatus: [
        {
          label: "Rascunho",
          value: "draft",
        },
        {
          label: "Publicado",
          value: "published",
        },
      ],
      currency: [
        {
          label: "R$",
          value: "brl",
        },
        {
          label: "U$",
          value: "usd",
        },
        {
          label: "€",
          value: "eur",
        },
      ],
    };
  },
  watch: {
    tempProduct(newValue) {
      this.tempProduct = newValue;
    },
  },
  methods: {
    changeProductPage(current) {
      this.product.pagination.page = current;
      this.getProducts();
    },
    productTableChange(pagination, filters, sorter) {
      filters;
      pagination;
      console.log(filters, pagination);
      this.product.filters.order =
        sorter.order != undefined ? sorter.order : "desc";
      this.product.filters.orderBy =
        sorter.column != undefined ? sorter.column.key : "created";
      this.getProducts();
    },
    productChangePageSize(current, pageSize) {
      this.product.pagination.page = current;
      this.product.pagination.perPage = pageSize;
      this.getProducts();
    },
    productFilters() {
      let filters = "",
        queryParams = new URLSearchParams("");
      history.pushState(null, null, "");

      if (!this.$root.isAdmin() && !this.$root.isAnalyst()) {
        filters += `&user_id=${this.$store.state.userData.id}`;
      } else {
        if (this.product.filters.users.selected.length > 0) {
          filters += `&user_id=${this.product.filters.users.selected}`;
          queryParams.set("user", `${this.product.filters.users.selected}`);
        }
      }

      if (this.product.filters.name) {
        filters += `&name=${this.product.filters.name}`;
        queryParams.set("name", `${this.product.filters.name}`);
      }

      if (this.product.filters.companies.selected.length > 0) {
        filters += `&company_id=${this.product.filters.companies.selected}`;
      }

      if (this.product.filters.companyBranches.selected.length > 0) {
        filters += `&company_branch_id=${this.product.filters.companyBranches.selected}`;
      }

      if (this.product.filters.status.selected.length > 0) {
        filters += `&status=${this.product.filters.status.selected}`;
        queryParams.set("status", `${this.product.filters.status.selected}`);
      }

      if (this.product.filters.situation.selected.length > 0) {
        filters += `&situation=${this.product.filters.situation.selected}`;
        queryParams.set(
          "situation",
          `${this.product.filters.situation.selected}`
        );
      }

      if (this.product.filters.period.selected.length > 0) {
        filters += `&period=${this.product.filters.period.selected[0]}|${this.product.filters.period.selected[1]}`;
        queryParams.set(
          "period",
          `${this.product.filters.period.selected[0]}|${this.product.filters.period.selected[1]}`
        );
      }

      if (this.product.filters.price.min || this.product.filters.price.max) {
        let min = this.product.filters.price.min;
        let max = this.product.filters.price.max;

        if (min) {
          if (!max) {
            max = "500000";
          }
        }

        if (max) {
          if (!min) {
            min = "0";
          }
        }

        filters += `&price-range=${min}-${max}`;
      }

      if (this.product.filters.id) {
        filters += `&id=${this.product.filters.id}`;
      }

      filters += `&order=${this.product.filters.order}&order-by=${this.product.filters.orderBy}`;

      history.replaceState(null, null, "?" + queryParams.toString());

      return filters;
    },
    setupTempProduct() {
      this.tempProduct = {
        ...this.product.details,
        user_id: this.product.details.user.id,
        company_id: this.product.details.company.id,
        company_branch_id: this.product.details.company_branch.id,
        customer_id: this.product.details?.customer?.id,
      };

      // PRODUTO SEM ALTERAÇÕES
      this.productFromDatabase = {
        ...this.product.details,
        user_id: this.product.details.user.id,
        company_id: this.product.details.company.id,
        company_branch_id: this.product.details.company_branch.id,
      };

      // TRATAMENTO DO OBJ tempProduct
      let allFieldsSize = Object.values(this.product.details.meta).length,
        values = Object.values(this.product.details.meta),
        keys = Object.keys(this.product.details.meta);

      for (let i = 0; i < allFieldsSize; i++) {
        this.tempProduct[keys[i]] = values[i];
        this.productFromDatabase[keys[i]] = values[i];
      }

      delete this.tempProduct.meta;
      delete this.tempProduct.raw;
      delete this.productFromDatabase.meta;
      delete this.productFromDatabase.raw;
    },
    async getProductById(id, encryptImg) {
      try {
        let type = "product";
        let enc = "";
        if (encryptImg == true) enc = `&encrypt-img=yes`;
        if (this.isBudget) type = "product-budget";

        const response = await this.$http.get(
          `/${type}/details?id=${id}${enc}`
        );
        this.product.details = response.data;
        this.setupTempProduct();
      } catch (error) {
        console.log(error);
        this.$message.error("Houve algum erro ao carregar os dados.");
        this.product.details = [];
      }
    },
    async getProducts(samePage) {
      this.product.loading = true;
      try {
        let type = "product";
        if (this.isBudget) type = "product-budget";
        const { data } = await this.$http.get(
          `/${type}/list?page=${this.product.pagination.page}&per_page=${
            this.product.pagination.perPage
          }${this.productFilters()}`
        );

        this.product.list = data.data;
        this.product.pagination.page = data.meta.next_page;
        this.product.pagination.total = data.meta.total;

        if (!samePage) {
          this.product.pagination.page = 1;
        }
      } catch (e) {
        this.product.list = [];
        this.product.pagination.page = 0;
        this.product.pagination.total = 0;
        e;
      } finally {
        this.product.loading = false;
      }
    },
  },
};
