<template>
  <div>
    <div class="label">Produto para gerar orçamento</div>
    <a-auto-complete
      class="w100"
      placeholder="Buscar pelo nome do produto..."
      option-label-prop="value"
      autoFocus
      v-model="selectedProduct"
      @select="onSelectProduct"
      @change="onSearchProduct"
    >
      <template slot="dataSource">
        <a-select-option
          v-for="(product, index) in product.list"
          :optionLabelProp="product.id"
          :key="index"
          :selected="product.id"
          :value="`${product.id} - ${product.name}`"
        >
          {{ product.id }} - {{ product.name }}
        </a-select-option>
      </template>
      <a-input class="travel-input" placeholder="Origem">
        <a-icon
          slot="prefix"
          :type="product.loading ? 'loading' : 'field-svg'"
        />
      </a-input>
    </a-auto-complete>
    <div class="a-center mt-20">
      <a-button
        type="primary"
        :disabled="tempProduct.id ? false : true"
        @click="onClickGenerateBudget"
      >
        Gerar orçamento
      </a-button>
    </div>
  </div>
</template>

<script>
// libs
import _ from "lodash";

// reuse
import productReusables from "../mixins/productReusables.js";

export default {
  name: "GenerateBudgetModal",
  mixins: [productReusables],
  data() {
    return {
      selectedProduct: "",
    };
  },
  mounted() {},
  methods: {
    onSearchProduct: _.debounce(function (e) {
      if (e.length > 1) {
        this.product.filters.name = e;
        this.getProducts();
      }
    }, 600),
    onSelectProduct(val, option) {
      this.getProductById(option?.data?.attrs?.selected);
      val;
    },
    onClickGenerateBudget() {
      let productBudget = this.tempProduct;
      productBudget.user_id = this.$store.state.userData.id;
      productBudget.situation = "";
      productBudget.status = "draft";

      this.$http
        .post("/product-budget/create", productBudget)
        .then(({ data }) => {
          this.$message.success(data.message);
          this.$router.push(`/products-budget/edit/${data.id}`);
          this.$emit("onGenerateProductBudget");
        })
        .catch(({ response }) => {
          this.$message.error(response.data.message);
        });
    },
  },
};
</script>

<style lang="sass" scoped>
.label
  font-size: 10px
  font-weight: 500
  color: #ccc
  top: 13px
  left: 0
  line-height: 1
  text-overflow: ellipsis
  width: 100%
  white-space: nowrap
</style>
